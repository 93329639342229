import "./SpaceHomeV2.scss";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import { THEME, ThemeProvider } from "App/common/ThemeProvider";
import { useSpaceContext } from "App/common/useSpace";
import { useAuth } from "App/components/Auth";
import {
  getEntitySubscribers,
  isSpacePrivateInboxEnabled,
  PERMISSION,
  stripExtension,
} from "@highnote/server/src/core/shared-util";
import { useHistory } from "react-router";
import {
  useGlobalAudioPlayer,
  useHTMLAudioPlayer,
} from "App/components/GlobalAudioPlayer";
import {
  GlobalAudioPlayer,
  GlobalAudioTrackPreview,
} from "App/components/GlobalAudioPlayer/GlobalAudioPlayer";
import {
  AttachmentsContextProvider,
  useAttachmentsContext,
} from "App/common/useAttachments";
import { EntitySortMenu } from "App/components/EntityLibrary/EntitySortMenu";
import { FileDrop } from "App/common/FileDrop";
import { UPLOAD_GROUP, useFiles } from "App/components/useFiles";
import { highnote } from "@highnote/server/src/sdk";
import { SpaceHomeNavigation } from "./SpaceNavigation";
import { useToast } from "App/common/useToast";
import { useNotificationsContext } from "App/common/useNotifications";
import {
  AUDIO_ACCEPT_TYPES,
  COLLECTION_ID,
  JOB_STATUS,
  KNOCK_WORKFLOW,
  ROLE,
  Roles,
} from "@highnote/server/src/core/entities";
import { useEntityShareKeyInUrl } from "App/components/ShareDialog/Members/InviteByLink";
import { JOIN_ENTITY_TRIGGER } from "App/components/Auth/util";
import { SpaceItems } from "./SpaceItems";
import { useChildEntityWatchers } from "App/store/helpers/useChildEntityWatchers";
import { DownloadRequestsContextProvider } from "App/components/useEntities/useDownloadRequests";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useGlobalTracks } from "App/store/tracks/useGlobalTracks";
import { DropboxJobsContextProvider } from "App/components/useEntities/useDropboxDownloads";
import {
  DraftSpaceOnboardDialog,
  PrivateInboxVisitDialog,
} from "./SpaceDialogs";
import {
  APP_FEATURES,
  AppFeaturesStatus,
} from "@highnote/server/src/core/features";
import { useCreatedTracks } from "App/components/useEntities/useCreatedTracks";
import { JoinSpaceRequestDialog } from "./JoinSpaceRequestDialog";
import { ConnectedUserName } from "App/common/UserAvatar/UserAvatar";
import { useUrlContext } from "../useUrlContext";
import { useMoveToSpace } from "App/components/useEntities/useLibraryTracks/useMoveToSpace";
import { useMobileAppParams } from "../useMobileAppParams";
import { SpaceHero } from "./SpaceHero/SpaceHero";
import { Container } from "App/common/Container/Container";
import { SpaceChatSidebar } from "App/components/Sidebar/SpaceChatSidebar";
import { SpacePageActions } from "./SpacePageActions/SpacePageActions";
import { Skeleton } from "App/common/Skeleton/Skeleton";
import { ReactComponent as SpacePlaylistSVG } from "App/common/icons-v2/space-template-playlist.svg";
import { ReactComponent as SpaceWorkspaceSVG } from "App/common/icons-v2/space-template-workspace.svg";
import { ReactComponent as SpaceBlankSVG } from "App/common/icons-v2/space-template-blank.svg";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import LoadingScreen from "App/common/LoadingScreen/LoadingScreen";

const SpaceHomeUI = () => {
  const { spaceId, trackId } = useUrlContext();
  const { showMoveToSpaceDialog } = useMobileAppParams();
  const { openMoveToSpace } = useMoveToSpace();
  const { user, joinEntity, isAllowed } = useAuth();
  const { toasted } = useToast();
  const { space, itemSortType, setItemSortType, spaceLoading } =
    useSpaceContext();
  const { globalSpaces } = useGlobalSpaces();
  const { globalTracks } = useGlobalTracks();
  const { createdTracks } = useCreatedTracks();
  const history = useHistory();
  const { getChildSpaces, getChildTracks } = useChildEntityWatchers();
  const [isTemplateSelectionLoading, setIsTemplateSelectionLoading] =
    useState(false);

  const searchParams = new URLSearchParams(location.search);
  const requester = searchParams.get("requester");

  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);
  const isInboxGuest = isSpacePrivateInboxEnabled(space) && !isMember;

  const childSpaces = useMemo(
    () => getChildSpaces(space?.id),
    [globalSpaces.childSpaces.get(space.id)],
  );

  const spaceTracks = useMemo(() => {
    return isInboxGuest ? createdTracks : getChildTracks(space?.id);
  }, [isInboxGuest, globalTracks.childTracks.get(space.id), createdTracks]);

  const spaceAttachments = useMemo(() => {
    return isInboxGuest ? [] : space?.files || [];
  }, [isInboxGuest, space?.files]);

  const { markAsRead, notifications } = useNotificationsContext();
  const { uploadFiles, getUploadCache, removeUpload } = useFiles();
  const { attachFileToContext } = useAttachmentsContext();
  useEntityShareKeyInUrl(space);

  const [isPrivateInboxModalOpen, setIsPrivateInboxModalOpen] = useState(false);
  const [isDraftSpaceModalOpen, setIsDraftSpaceModalOpen] = useState(false);
  const [isJoinSpaceRequestDialogOpen, setIsJoinSpaceRequestDialogOpen] =
    useState(Boolean(requester));

  useEffect(() => {
    const unlisten = history.listen(() => {
      const searchParams = new URLSearchParams(location.search);
      setIsJoinSpaceRequestDialogOpen(Boolean(searchParams.get("requester")));
    });
    return () => {
      unlisten();
    };
  }, []);

  const fileDragRef = useRef<HTMLDivElement>();
  const unmountedRef = useRef<boolean>(false);

  const canAdd = isAllowed(PERMISSION.TO_ADD_TO_SPACE, { space });

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  useEffect(() => {
    if (showMoveToSpaceDialog && spaceId && !trackId && space) {
      openMoveToSpace({
        entity: space,
        entityType: COLLECTION_ID.SPACE,
      });
    }
  }, [spaceId, trackId, showMoveToSpaceDialog, space]);

  useEffect(() => {
    if (!space) return;
    const unreads = notifications.filter(
      (n) =>
        n.source.key === KNOCK_WORKFLOW.SPACE_MEMBER_ADDED &&
        n.data.spaceId === space?.id &&
        !n.read_at,
    );
    markAsRead(unreads);
  }, [space, notifications]);

  const onApproveJoinSpaceRequest = useCallback(
    async (userId: string) => {
      const newRoles: Roles = {
        [`rolesV2,${userId}`]: [
          ROLE.VIEW,
          ROLE.COMMENT,
          ROLE.UPLOAD,
          ROLE.DOWNLOAD,
        ],
      };

      await toasted({
        promise: highnote.updateSpace({
          id: space.id,
          data: newRoles,
        }),
        successMessage: (
          <span>
            <ConnectedUserName userId={searchParams.get("requester")} />
            {"  "}
            {`was added to ${space.name}.`}
          </span>
        ),
        errorMessage: (
          <span>
            <ConnectedUserName userId={searchParams.get("requester")} />
            {"  "}
            {`could not be added to ${space.name}.`}
          </span>
        ),
      })
        .then(() => setIsJoinSpaceRequestDialogOpen(undefined))
        .finally(() =>
          setTimeout(() => {
            history.replace(`/space/${space.id}`);
          }, 500),
        );
    },
    [toasted, space],
  );

  const onUploadFiles = useCallback(
    async (files: File[]) => {
      // if the user has already been introduced to the Private Inbox,
      if (isPrivateInboxModalOpen) {
        setIsPrivateInboxModalOpen(false);
      }

      if (files.length < 1) return;
      const uploadPayloads = files.map((file) => {
        const asTrack = AUDIO_ACCEPT_TYPES.includes(file.type);
        const cache = asTrack
          ? getUploadCache(UPLOAD_GROUP.TRACKS_BY_SPACE, space?.id)
          : getUploadCache(UPLOAD_GROUP.ATTACHMENTS_BY_SPACE, space?.id);
        return {
          file,
          cache,
        };
      });

      await uploadFiles({
        payloads: uploadPayloads,
        onSuccess: async (file, fileEntity) => {
          const asTrack = AUDIO_ACCEPT_TYPES.includes(file.type);
          if (asTrack) {
            await highnote.createTrack({
              id: uuidv4(),
              data: {
                title: stripExtension(fileEntity.fileName),
                versionFilesV2: [fileEntity.id],
                spaceId: space?.id,
              },
            });
          } else {
            await attachFileToContext(fileEntity.id);
          }

          joinEntity({
            entity: space,
            entityType: "Space",
            trigger: JOIN_ENTITY_TRIGGER.UPLOAD,
          });

          removeUpload(fileEntity.id);
        },
      });
    },
    [space?.id, user],
  );

  const spaceItems = [...spaceTracks, ...childSpaces, ...spaceAttachments];

  useEffect(() => {
    if (!user || !space) return;

    const draftSpaceOnboarded = Boolean(user?.draftSpaceOnboarded);
    if (
      !draftSpaceOnboarded &&
      space.isDraftSpace &&
      space.createdBy === user.id
    ) {
      setIsDraftSpaceModalOpen(true);
    }

    const hasIntroducedToPrivateInbox = Boolean(
      user?.hasIntroducedToPrivateInbox,
    );
    const isPrivateInboxEnabled = isSpacePrivateInboxEnabled(space);
    if (!hasIntroducedToPrivateInbox && isPrivateInboxEnabled) {
      setIsPrivateInboxModalOpen(true);
    }
  }, [user, space]);

  const isImplicitlyNewSpace =
    spaceItems.length === 0 &&
    !space.description &&
    !space.artworkFile &&
    !space.chatEnabled;

  // If the user has the permission to manage the space, they can see the getting started options
  // space.spaceSetup is a boolean when a template is selected. Undefined when a template is not selected.
  // space.spaceSetup = true when a template is a blank space, and false when a template is a playlist or workspace.
  // We use space.spaceSetup to identify if a template was selected and if that template is a blank space.
  // TODO: Find a better way to identify if a template was selected and if that template is a blank space.
  // TODO: Find a better type for space.spaceSetup
  const showGettingStartedOptions =
    isAllowed(PERMISSION.TO_MANAGE_SPACE, {
      space,
    }) &&
    typeof space.spaceSetup !== "boolean" &&
    isImplicitlyNewSpace;

  const playListDescription = "Share demos and EPK's";
  const workspaceDescription = "Share demos, EPK's and chat with your team";

  // Each template initializes with different default configurations:
  // - Playlist: artwork=true, description=true, chat=false, spaceSetup=false
  // - Workspace: artwork=true, description=true, chat=true, spaceSetup=false
  // - Blank: artwork=false, description=false, chat=false, spaceSetup=true
  // For more info see @highnote/client/README.md#new-space-templates
  const onTemplateClick = async (
    template: "playlist" | "workspace" | "blank",
  ) => {
    setIsTemplateSelectionLoading(true);
    await toasted({
      promise: highnote.updateSpace({
        id: space.id,
        data: {
          // If the template is a blank space, set spaceSetup to true
          // If the template is a playlist or workspace, set spaceSetup to false
          // spaceSetup is undefined when a template is not selected.
          // TODO: Find a better way to identify if a template was selected and if that template is a blank space.
          // TODO: Find a better type for space.spaceSetup
          spaceSetup: template === "blank",
          description:
            template === "playlist"
              ? playListDescription
              : template === "workspace"
                ? workspaceDescription
                : "",
          chatEnabled: template === "workspace",
        },
      }),
      errorMessage: `Could not apply the ${template} template to ${space.name}. Please try again.`,
    }).finally(() => {
      setIsTemplateSelectionLoading(false);
    });
  };

  return (
    <ThemeProvider theme={THEME.DARK}>
      <div
        className="highnote-space-home"
        data-cypress-id="highnote-space-home"
      >
        {canAdd && (
          <FileDrop
            dragRef={fileDragRef}
            onDrop={onUploadFiles}
            instruction={"Add files to this Space by dropping them here"}
          />
        )}

        {AppFeaturesStatus[APP_FEATURES.PRIVATE_INBOX] && (
          <PrivateInboxVisitDialog
            spaceOwner={space.createdBy}
            isOpen={isPrivateInboxModalOpen}
            onUploadFiles={onUploadFiles}
            onClose={() => {
              setIsPrivateInboxModalOpen(false);
              highnote.updateUser({
                id: user.id,
                data: { hasIntroducedToPrivateInbox: true },
              });
            }}
          />
        )}
        <DraftSpaceOnboardDialog
          isOpen={isDraftSpaceModalOpen}
          onClose={() => {
            setIsDraftSpaceModalOpen(false);

            highnote.updateUser({
              id: user.id,
              data: { draftSpaceOnboarded: true },
            });
          }}
        />

        {isJoinSpaceRequestDialogOpen && (
          <JoinSpaceRequestDialog
            onClose={() => {
              const searchParams = new URLSearchParams(location.search);
              searchParams.delete("requester");
              history.replace(
                location.pathname + "?" + searchParams.toString(),
              );
              setIsJoinSpaceRequestDialogOpen(false);
            }}
            requesterId={requester}
            spaceName={space.name}
            onApprove={onApproveJoinSpaceRequest}
            onDeny={() => {
              setIsJoinSpaceRequestDialogOpen(false);
              history.replace(`/space/${space.id}`);
            }}
          />
        )}

        <SpaceHomeNavigation />

        <div className="content-container" ref={fileDragRef}>
          <div
            // Created an outer div to allow the floating action button to
            // seem as a sticky position. Children of a scrollable container
            // conflict with position: sticky, so this is a workaround.
            className={classNames("main-content-container", {
              "full-width": !space.chatEnabled,
            })}
          >
            <div className="scrollable">
              <Container variant="container-xl">
                <SpaceHero
                  spaceItemsCount={spaceItems.length}
                  spaceTracksCount={spaceTracks.length}
                />
              </Container>

              <div
                className="entity-library-container"
                data-cypress-id="library-container"
              >
                {spaceLoading ? (
                  <div className="entity-library-items-sort-row">
                    <Skeleton width={32} height={24} />
                    <Skeleton width={32} height={24} />
                  </div>
                ) : (
                  <>
                    {spaceItems.length > 0 && (
                      <div className="count-sort-container">
                        <div className="entity-count">
                          {spaceItems.length} Items
                        </div>
                        {isMember && (
                          <EntitySortMenu
                            sortType={itemSortType}
                            onChange={setItemSortType}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}

                {isTemplateSelectionLoading ? (
                  <div className="space-template-options">
                    <LoadingScreen />
                  </div>
                ) : showGettingStartedOptions ? (
                  <div className="space-template-options">
                    <span className="space-template-options-title">
                      Get started with a template:
                    </span>
                    <Button
                      theme={BUTTON_THEME.PRIMARY}
                      size={BUTTON_SIZE.AUTO}
                      className="space-template-option"
                      onClick={() => onTemplateClick("playlist")}
                    >
                      <SpacePlaylistSVG />
                      <div className="space-template-option-text">
                        <span>Playlist</span>
                        <span>{playListDescription}</span>
                      </div>
                    </Button>
                    <Button
                      theme={BUTTON_THEME.PRIMARY}
                      size={BUTTON_SIZE.AUTO}
                      className="space-template-option"
                      onClick={() => onTemplateClick("workspace")}
                    >
                      <SpaceWorkspaceSVG />
                      <div className="space-template-option-text">
                        <span>Workspace</span>
                        <span>{workspaceDescription}</span>
                      </div>
                    </Button>
                    <span className="space-template-options-title">OR</span>
                    <Button
                      theme={BUTTON_THEME.PRIMARY}
                      size={BUTTON_SIZE.AUTO}
                      className="space-template-option"
                      onClick={() => onTemplateClick("blank")}
                    >
                      <SpaceBlankSVG />
                      <div className="space-template-option-text">
                        <span>Blank space</span>
                        <span>Customize your space from scratch</span>
                      </div>
                    </Button>
                  </div>
                ) : (
                  <SpaceItems sortType={itemSortType} />
                )}
              </div>
            </div>
            {canAdd && (
              <SpacePageActions
                onUploadFiles={onUploadFiles}
                triggerProps={{
                  className: "floating-action-button-container",
                }}
              />
            )}
          </div>
          <div className="entity-space-chat-container">
            <div className="entity-space-chat">
              <SpaceChatSidebar isOutsideSidebar />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const SpaceHomePlayer = () => {
  const history = useHistory();
  const { spaceId } = useSpaceContext();
  const { goToTrack, nowPlaying, prevTrackId } = useGlobalAudioPlayer();
  const { useHTMLPlayer, currentTime, onSeek } = useHTMLAudioPlayer();

  const playbackProps = {
    ...(useHTMLPlayer && {
      onPrev: () => {
        if (currentTime > 1) {
          onSeek(0);
          return;
        }
        goToTrack(prevTrackId);
      },
    }),
  };

  const preview = useMemo(() => {
    const track = nowPlaying.track;

    return (
      <GlobalAudioTrackPreview
        track={track}
        onClick={() => {
          history.push(`/space/${spaceId}/${track.id}`);
        }}
      />
    );
  }, [nowPlaying.track, spaceId]);

  return <GlobalAudioPlayer preview={preview} {...playbackProps} />;
};

export const SpaceHome = () => {
  const { space } = useSpaceContext();
  return (
    <AttachmentsContextProvider space={space} attachmentIds={space?.files}>
      <DownloadRequestsContextProvider isProcessing>
        <DropboxJobsContextProvider
          spaceId={space?.id}
          statuses={[
            JOB_STATUS.PENDING,
            JOB_STATUS.IN_PROGRESS,
            JOB_STATUS.FAILED,
          ]}
        >
          <SpaceHomeUI />
        </DropboxJobsContextProvider>
      </DownloadRequestsContextProvider>
      <SpaceHomePlayer />
    </AttachmentsContextProvider>
  );
};
