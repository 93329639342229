import React, { useEffect, useMemo, useState } from "react";
import { highnote } from "@highnote/server/src/sdk";
import { useToast } from "./useToast";
import {
  ArrayRemove,
  ArrayUnion,
  COLLECTION_ID,
  DELETE_FIRESTORE_FIELD,
  PUBLIC_ID,
  ROLE,
  Roles,
  Space,
} from "@highnote/server/src/core/entities";
import { useSpaceContext } from "./useSpace";
import { useHistory } from "react-router";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { Action, Actions } from "App/common/Actions";
import { useConfirmation } from "App/common/useConfirmation";
import { ReactComponent as ShareSVG } from "App/common/icons/share-arrow.svg";
import { ReactComponent as RemoveSVG } from "App/common/icons/remove.svg";
import { ReactComponent as MoveSVG } from "App/common/icons/collection-move.svg";
import { ReactComponent as RenameSVG } from "App/common/icons/edit.svg";
import { ReactComponent as FolderHollowSVG } from "App/common/icons/folder-hollow.svg";
import { ReactComponent as ArchiveSVG } from "App/common/icons/archive.svg";
import { ReactComponent as DeleteSVG } from "App/common/icons/trash.svg";
import { ReactComponent as InfoSVG } from "App/common/icons/track-list.svg";
import { ReactComponent as ExitSVG } from "App/common/icons/exit.svg";
import { useAuth } from "App/components/Auth";
import {
  getEntitySubscribers,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { getMoveToSpaceWarning } from "App/components/util";
import { useHighnote } from "./useHighnote";
import { useShareDialog } from "App/components/ShareDialog";
import { ReactComponent as BellSVG } from "App/common/icons/bell.svg";
import { ReactComponent as BellMutedSVG } from "App/common/icons/bell-muted.svg";
import { useSpaceEditor } from "App/components/useSpaceEditor";
import { useMoveToSpace } from "App/components/useEntities/useLibraryTracks/useMoveToSpace";
import { getChildEntities } from "App/services/firebase/entities/helpers";
import { useGlobalSpaces } from "App/store/spaces/useGlobalSpaces";
import { useCreateSpaceDialog } from "App/components/Dialog/CreateSpace/useCreateSpaceDialog";

enum DEFAULT_SPACE_ACTION {
  SHARE = "share",
  RENAME = "rename",
  NEW_SPACE = "new-space",
  DETAILS = "details",
  TOGGLE_ARCHIVE = "toggle-archive",
  MOVE_TO_SPACE = "move-to-space",
  DELETE = "delete",
  REMOVE = "remove",
  REMOVE_MYSELF = "remove-myself",
  TOGGLE_MUTE = "toggle-mute",
  // Not an action, just a way to add a separator to the menu
  SEPARATOR = "separator",
}

const SpaceActions = ({
  space,
  actions,
}: {
  space: Space;
  actions: (DEFAULT_SPACE_ACTION | Action)[];
}) => {
  const history = useHistory();
  const { isAllowed, user } = useAuth();
  const { confirm, renderConfirmation } = useConfirmation();
  const { openShareDialog, renderShareDialog } = useShareDialog();
  const { openCreateSpaceDialog, renderCreateSpaceDialog } =
    useCreateSpaceDialog();
  const { toasted } = useToast();
  const { spaceId: activeSpaceId } = useSpaceContext();
  const { openMoveToSpace } = useMoveToSpace();
  const { moveToSpace } = useHighnote();
  const subscribers = getEntitySubscribers(space);
  const isMember = subscribers.includes(user?.id);
  const mutedSpaces = user?.mutedSpaces || [];
  const muted = mutedSpaces.includes(space.id);
  const { openSpaceEditor } = useSpaceEditor();

  const deleteSpace = async () => {
    try {
      const { childTracks } = await getChildEntities(space.id);

      const numPeople = Object.keys(space.rolesV2 || {}).filter(
        (r) => r !== PUBLIC_ID,
      ).length;
      const totalItemsCount = [...(space.files || []), ...(childTracks || [])]
        .length;
      const messages = [
        ...(numPeople > 0
          ? [
              `This space is shared with ${numPeople} ${numPeople === 1 ? "person" : "people"}.`,
            ]
          : []),
        ...(totalItemsCount > 0
          ? [
              `All ${totalItemsCount} item(s) inside ${space.name} will be permanently deleted or removed.`,
            ]
          : []),
      ];

      await confirm({
        title: `Delete ${space.name}?`,
        body: (
          <p>
            {messages.join(" And ")}
            {messages.length > 0 ? (
              <>
                <br />
                <br />
              </>
            ) : null}
            <strong>Are you sure you want to delete {space.name}?</strong> This
            cannot be undone.
          </p>
        ),
      });

      await toasted({
        promise: highnote.deleteSpace({ id: space.id }),
        createMessage: `Deleting Space ${space.name}...`,
        successMessage: `Space ${space.name} deleted.`,
        errorMessage: `Could not delete Space ${space.name}. Please try again.`,
      });

      if (activeSpaceId === space.id) {
        history.push("/space");
      }
    } catch (e) {
      console.error("Error deleting space", e);
    }
  };

  const archiveSpace = () => {
    const verbRoot = space.isArchived ? "Unarchiv" : "Archiv";
    toasted({
      promise: highnote.updateSpace({
        id: space.id,
        data: { isArchived: !space.isArchived },
      }),
      createMessage: `${verbRoot}ing ${space.name}...`,
      errorMessage: `Could not ${verbRoot.toLowerCase()}e ${
        space.name
      }. Please try again.`,
      successMessage: `${verbRoot}ed ${space.name}.`,
    });
  };

  const removeFromSpace = async () => {
    const warning = await getMoveToSpaceWarning({
      entity: space,
      entityType: COLLECTION_ID.SPACE,
      spaceDestination: undefined,
      userId: user.id,
    });

    if (warning) {
      await confirm({
        title: warning.title,
        body: warning.description,
      });
    }

    moveToSpace({
      entityId: space.id,
      entityType: COLLECTION_ID.SPACE,
      newSpaceId: undefined,
    });
  };

  const updateEntityRoles = async (newRoles: Roles) => {
    const newRolesAsUpdateData: Roles = {};

    Object.entries(newRoles).forEach(([userId, userRoles]) => {
      newRolesAsUpdateData[`rolesV2,${userId}`] =
        userRoles || (DELETE_FIRESTORE_FIELD as unknown as ROLE[]);
    });

    await toasted({
      promise: highnote.updateSpace({
        id: space.id,
        data: newRolesAsUpdateData,
      }),
      createMessage: `Removing access from ${space.name}...`,
      successMessage: `Access removed from ${space.name}.`,
      errorMessage: `Could not remove access from ${space.name}. Please try again.`,
    });
  };

  const removeMyselfFromSpace = async () => {
    await confirm({
      title: `Removing yourself from ${space.name}`,
      body: (
        <p>
          <strong>
            {" "}
            Are you sure you want to remove yourself from {space.name}?
          </strong>{" "}
          You will no longer have access to this space.{" "}
        </p>
      ),
    });

    const newRoles: { [x: string]: ROLE[] } = {
      [user.id]: null,
    };

    try {
      await updateEntityRoles(newRoles);
    } catch (e) {
      console.error("Error removing access from space", e);
    }
  };

  const openSpaceShare = () => {
    openShareDialog(space);
  };

  const createNewSpace = () => {
    openCreateSpaceDialog({
      parentSpace: space,
    });
  };

  if (!space) return null;

  return (
    <ErrorBoundary name="SpaceActions">
      <Actions
        actions={actions.map((action) => {
          if (action === DEFAULT_SPACE_ACTION.SEPARATOR) {
            return { name: "SEPARATOR" };
          }

          if (action === DEFAULT_SPACE_ACTION.NEW_SPACE) {
            const canAddSpaceToSpace = isAllowed(
              PERMISSION.TO_ADD_SPACE_TO_SPACE,
              { space },
            );
            return {
              name: "New Space",
              icon: <FolderHollowSVG />,
              onClick: createNewSpace,
              disabled: !canAddSpaceToSpace,
            };
          }

          if (action === DEFAULT_SPACE_ACTION.SHARE) {
            return {
              name: "Share",
              icon: <ShareSVG />,
              onClick: openSpaceShare,
            };
          }

          if (action === DEFAULT_SPACE_ACTION.TOGGLE_ARCHIVE) {
            if (!isAllowed(PERMISSION.TO_ARCHIVE_SPACE, { space })) return null;
            return {
              name: space.isArchived ? "Unarchive" : "Archive",
              icon: <ArchiveSVG />,
              onClick: archiveSpace,
            };
          }

          if (action === DEFAULT_SPACE_ACTION.RENAME) {
            if (!isAllowed(PERMISSION.TO_MANAGE_SPACE, { space })) return null;
            return {
              name: "Rename",
              icon: <RenameSVG />,
              onClick: () => openSpaceEditor(space),
            };
          }

          if (action === DEFAULT_SPACE_ACTION.MOVE_TO_SPACE) {
            return {
              name: "Move to...",
              icon: <MoveSVG />,
              onClick: () =>
                openMoveToSpace({
                  entity: space,
                  entityType: COLLECTION_ID.SPACE,
                }),
            };
          }

          if (action === DEFAULT_SPACE_ACTION.TOGGLE_MUTE) {
            if (!isMember) return null;
            return {
              name: muted ? "Unmute" : "Mute",
              icon: muted ? <BellSVG /> : <BellMutedSVG />,
              onClick: () => {
                highnote.updateUser({
                  id: user.id,
                  data: {
                    mutedSpaces: muted
                      ? (new ArrayRemove([space.id]) as unknown as string[])
                      : (new ArrayUnion([space.id]) as unknown as string[]),
                  },
                });
              },
            };
          }

          if (action === DEFAULT_SPACE_ACTION.REMOVE) {
            if (!space.spaceId) return null;
            return {
              name: "Remove from Space",
              icon: <RemoveSVG />,
              onClick: removeFromSpace,
            };
          }

          if (action === DEFAULT_SPACE_ACTION.DELETE) {
            if (!isAllowed(PERMISSION.TO_DELETE_SPACE, { space })) return null;
            return {
              name: "Delete Space",
              icon: <DeleteSVG />,
              onClick: deleteSpace,
              warn: true,
            };
          }

          if (action === DEFAULT_SPACE_ACTION.DETAILS) {
            return {
              name: "Details",
              onClick: () => {
                openSpaceEditor(space);
              },
              icon: <InfoSVG />,
            };
          }

          if (action === DEFAULT_SPACE_ACTION.REMOVE_MYSELF) {
            return {
              name: "Remove Myself",
              onClick: removeMyselfFromSpace,
              icon: <ExitSVG />,
              warn: true,
            };
          }

          return action;
        })}
      />
      {renderConfirmation}
      {renderShareDialog}
      {renderCreateSpaceDialog}
    </ErrorBoundary>
  );
};

export const DefaultSpaceActions = ({ space }: { space: Space }) => {
  const { isAllowed } = useAuth();

  const { getGlobalSpace } = useGlobalSpaces();
  const [parentSpace, setParentSpace] = useState<Space | undefined>();

  useEffect(() => {
    let isMounted = true;

    if (isMounted && space.spaceId) {
      const globalSpace = getGlobalSpace(space.spaceId);
      if (globalSpace) {
        setParentSpace(globalSpace);
      } else {
        // TODO(globalState): This will rarely be used, when we implement the
        // new global space state in SpaceHome. Can keep as a fallback.
        highnote
          .getSpace({ id: space.spaceId, fromCache: true })
          .then((_space) => {
            setParentSpace(_space);
          })
          .catch((error) => {
            console.error("Error getting parent space", error);
          });
      }
    }

    return () => {
      isMounted = false;
    };
  }, [space.spaceId]);

  const actions = useMemo(() => {
    const canArchive = isAllowed(PERMISSION.TO_ARCHIVE_SPACE, { space });
    const canDelete = isAllowed(PERMISSION.TO_DELETE_SPACE, { space });
    const canManage = isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });
    const canManageParent =
      !space.spaceId ||
      isAllowed(PERMISSION.TO_MANAGE_SPACE, {
        space: parentSpace,
      });

    const canRemoveMyself = isAllowed(PERMISSION.TO_REMOVE_SELF_FROM_SPACE, {
      space,
    });

    if (canManage) {
      return [
        DEFAULT_SPACE_ACTION.SHARE,
        ...(canManageParent
          ? [
              DEFAULT_SPACE_ACTION.SEPARATOR,
              DEFAULT_SPACE_ACTION.DETAILS,
              DEFAULT_SPACE_ACTION.RENAME,
              DEFAULT_SPACE_ACTION.SEPARATOR,
              DEFAULT_SPACE_ACTION.NEW_SPACE,
              DEFAULT_SPACE_ACTION.MOVE_TO_SPACE,
              // If needed, add DEFAULT_SPACE_ACTION.REMOVE here
              // https://github.com/highnotefm/highnote/pull/991/files
            ]
          : []),
        DEFAULT_SPACE_ACTION.SEPARATOR,
        DEFAULT_SPACE_ACTION.TOGGLE_MUTE,
        ...(canArchive ? [DEFAULT_SPACE_ACTION.TOGGLE_ARCHIVE] : []),
        ...(canRemoveMyself
          ? [DEFAULT_SPACE_ACTION.SEPARATOR, DEFAULT_SPACE_ACTION.REMOVE_MYSELF]
          : []),
        ...(canDelete
          ? [DEFAULT_SPACE_ACTION.SEPARATOR, DEFAULT_SPACE_ACTION.DELETE]
          : []),
      ];
    }

    return [
      DEFAULT_SPACE_ACTION.TOGGLE_MUTE,
      DEFAULT_SPACE_ACTION.SEPARATOR,
      DEFAULT_SPACE_ACTION.DETAILS,
      // If needed, add DEFAULT_SPACE_ACTION.REMOVE here if they canManageParent
      // https://github.com/highnotefm/highnote/pull/991/files
      ...(canRemoveMyself
        ? [DEFAULT_SPACE_ACTION.SEPARATOR, DEFAULT_SPACE_ACTION.REMOVE_MYSELF]
        : []),
    ];
  }, [isAllowed, space, parentSpace]);

  return <SpaceActions space={space} actions={actions} />;
};
