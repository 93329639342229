import React, { useRef } from "react";
import classNames from "classnames";

import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { useAuth } from "App/components/Auth";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { CommentContextProvider, useCommentContext } from "../useComment";
import { useFocusOnCard } from "../useFocusedCard";
import { useSpaceContext } from "../useSpace";
import { useViewport } from "../useViewport";
import { CommentContent } from "./CommentContent";
import { CommentMeta } from "./CommentMeta";
import { ReplyStats } from "./ReplyStats";

import "./CommentCardPreview.scss";
import { Skeleton } from "../Skeleton/Skeleton";

type CommentCardPreviewProps = {
  containerClassName?: string;
  wrapperClassName?: string;
  localInstanceId?: string;
};
const _CommentCardPreview = ({
  containerClassName,
  wrapperClassName,
  localInstanceId,
}: CommentCardPreviewProps) => {
  const { focusOnCard } = useFocusOnCard();
  const { vw } = useViewport();
  const sync = useCarouselSync();
  const centeredCardId = sync.getCenteredCardId();
  const { comment, replies } = useCommentContext();
  const { isAllowed } = useAuth();
  const { space } = useSpaceContext();
  const scrollableRef = useRef<HTMLDivElement>();
  const isMobile = vw <= 768;

  if (!comment) return null;

  const canReplyToCommentInSpace = isAllowed(
    PERMISSION.TO_REPLY_TO_COMMENT_IN_SPACE,
    { space, comment },
  );

  const openReplies: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    focusOnCard(comment.id, undefined, { expand: true, localInstanceId });
  };

  return (
    <button
      className={classNames(
        "highnote-comment-card-preview",
        containerClassName,
      )}
      data-cypress-id="comment-card-preview"
      data-comment-id={comment.id}
      data-is-centered={centeredCardId === comment.id}
      data-is-resolved={!!comment.isResolved}
      data-is-deleted={comment.isDeleted}
      onClick={() => {
        if (!isMobile && centeredCardId === comment.id) {
          focusOnCard(comment.id);
        } else {
          focusOnCard().then(() => {
            sync.onCardIdClick(comment.id);
          });
        }
      }}
    >
      <div className={classNames("comment-wrapper", wrapperClassName)}>
        <div className="meta-wrapper">
          <CommentMeta showActions localInstanceId={localInstanceId} />
        </div>

        <div className="scrollable" ref={scrollableRef}>
          <div className="root-comment">
            <CommentMeta localInstanceId={localInstanceId} />
            <CommentContent isCollapsible />
          </div>
        </div>

        <div className="reply-buttons">
          {!!replies.length && (
            <button className="reply-stats-button" onClick={openReplies}>
              <ReplyStats replies={replies} />
            </button>
          )}
          {canReplyToCommentInSpace && (
            <button className="reply-button" onClick={openReplies}>
              Reply
            </button>
          )}
        </div>
      </div>
    </button>
  );
};

type Props = CommentCardPreviewProps & {
  id: string;
};
export const CommentCardPreview = ({ id, ...props }: Props) => {
  return (
    <CommentContextProvider id={id}>
      <_CommentCardPreview {...props} />
    </CommentContextProvider>
  );
};

export const CommentCardPreviewSkeleton = () => {
  return (
    <div className="highnote-comment-card-preview">
      <div className="comment-wrapper">
        <div className="meta-wrapper">
          <Skeleton />
        </div>
        <div className="scrollable">
          <div className="root-comment">
            <Skeleton height={80} />
          </div>
        </div>
        <div className="reply-buttons">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};
