import React, { useEffect, useRef, useState } from "react";
import { Drawer } from "@mui/material";

import { CommentCard } from "App/common/Comment/CommentCard";
import {
  CommentCardPreview,
  CommentCardPreviewSkeleton,
} from "App/common/Comment/CommentCardPreview";
import { ErrorBoundary } from "App/common/ErrorBoundary";
import { ReactComponent as FilterSVG } from "App/common/icons-v2/filter.svg";
import { useCommentCards } from "App/common/useCommentCards";
import { useFocusedCard } from "App/common/useFocusedCard";
import { CommentFilters } from "App/components/SpaceTools";
import { useTrack } from "App/components/useEntities/useTrack";
import { useTrackComments } from "App/components/useEntities/useTrackComments/useTrackComments";
import styles from "./TrackCommentSidebar.module.scss";

const TrackComments = () => {
  const { track } = useTrack();
  const { focusedCardId } = useFocusedCard();
  const { orderedCards } = useCommentCards();
  const { commentsLoading } = useTrackComments(track);

  if (commentsLoading) {
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => (
          <CommentCardPreviewSkeleton key={`comment-card-skeleton-${index}`} />
        ))}
      </>
    );
  }

  if (!commentsLoading && orderedCards.length === 0) {
    return <div className={styles["no-comments"]}>No comments found.</div>;
  }

  return (
    <div>
      {orderedCards.map((comment) => {
        if (focusedCardId === comment.id) {
          return (
            <React.Fragment key={comment.id}>
              <CommentCard
                containerClassName={styles["focused-comment-card"]}
                commentWrapperClassName={styles["focused-comment-wrapper"]}
                localInstanceId={`track-comment-sidebar-${comment.id}`}
              />
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={comment.id}>
            <CommentCardPreview
              id={comment.id}
              containerClassName={styles["comment-card-container"]}
              wrapperClassName={styles["comment-card"]}
              localInstanceId={`track-comment-sidebar-${comment.id}`}
            />
            <div className={styles["divider"]} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

type Props = {
  isSidebarOpen: boolean;
  onCloseSidebar: () => void;
};

const TrackCommentSidebarHeader = () => {
  return (
    <div className={styles.header}>
      <h2>All Comments</h2>
      <CommentFilters
        SelectProps={{
          renderValue: () => <></>, // To render nothing
          endAdornment: <FilterSVG />,
          className: styles["filter-select"],
          SelectDisplayProps: {
            className: styles["filter-select-display"],
            "aria-label": "Filter Comments",
          },
        }}
      />
    </div>
  );
};

export const TrackCommentSidebar = ({
  isSidebarOpen,
  onCloseSidebar,
}: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  // Save scroll position when the drawer is closing
  useEffect(() => {
    if (!isSidebarOpen && scrollContainerRef.current) {
      const currentScrollTop = scrollContainerRef.current.scrollTop;
      setScrollPosition(currentScrollTop); // Always save scroll position, even if 0
    }
  }, [isSidebarOpen]);

  // Restore scroll position after animation ends
  const handleTransitionEnd = () => {
    if (isSidebarOpen && scrollContainerRef.current) {
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      const clientHeight = scrollContainerRef.current.clientHeight;

      // Restore scroll position only if the content overflows the drawer height
      if (scrollHeight > clientHeight) {
        scrollContainerRef.current.scrollTop = scrollPosition;
      }
    }
  };

  return (
    <Drawer
      open={isSidebarOpen}
      onClose={onCloseSidebar}
      anchor="right"
      BackdropProps={{
        invisible: true,
      }}
      PaperProps={{
        className: styles.root,
      }}
      className={styles.drawer}
      title="All Comments"
      // We only want to run this ONCE on mount, so we use
      // onAnimationEnd on the drawer itself, not the content.
      onAnimationEnd={handleTransitionEnd}
    >
      <ErrorBoundary name="TrackCommentSidebar">
        <TrackCommentSidebarHeader />
        <div ref={scrollContainerRef} className={styles["scroll-container"]}>
          <TrackComments />
        </div>
      </ErrorBoundary>
    </Drawer>
  );
};
