import React from "react";

import { MAX_PINNED_VERSIONS } from "@highnote/server/src/core/shared-util";
import { ReactComponent as GiftFillSVG } from "App/common/icons-v2/gift-fill.svg";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import {
  BaseCalloutActionToolbar,
  BaseCalloutCard,
  BaseCalloutDescription,
  BaseCalloutTitle,
} from "../BaseCallout/BaseCallout";
import styles from "./PinnedVersionCallout.module.scss";

type Props = {
  className?: string;
};

export const PinnedVersionCallout = ({ className }: Props) => {
  const { showPlanPickerDialog } = usePlanLimitsContext();

  return (
    <BaseCalloutCard className={className}>
      <div className={styles["inner"]}>
        <div className={styles["content"]}>
          <BaseCalloutTitle>Unlock More Versions</BaseCalloutTitle>
          <BaseCalloutDescription>
            Pin and compare up to {MAX_PINNED_VERSIONS} versions of this Track
            instantly, and in real-time.
          </BaseCalloutDescription>
        </div>
        <BaseCalloutActionToolbar>
          <Button
            startIcon={<GiftFillSVG />}
            theme={BUTTON_THEME.MONETIZATION}
            size={BUTTON_SIZE.MEDIUM}
            onClick={showPlanPickerDialog}
          >
            Upgrade
          </Button>
        </BaseCalloutActionToolbar>
      </div>
    </BaseCalloutCard>
  );
};
