import React, { useState } from "react";

import { Dialog, DialogButtons, DialogSection } from "App/common/Dialog";
import { TextInput } from "App/common/TextInput";
import { useHighnote } from "App/common/useHighnote";
import { useToast } from "App/common/useToast";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { CreateSpaceProps } from "./useCreateSpaceDialog";

import styles from "./CreateSpace.module.scss";

type Props = {
  isOpen?: boolean;
  close: () => void;
} & CreateSpaceProps;

export const CreateSpaceWithNameDialog = ({
  isOpen,
  parentSpace,
  close,
  onConfirm,
}: Props) => {
  const { createSpace } = useHighnote();
  const { addErrorMessage } = useToast();
  const [inputValue, setInputValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const finalValue = inputValue?.trim();

    if (finalValue) {
      setIsSubmitting(true);
      try {
        const newSpace = await createSpace({
          data: {
            // If parentSpace.id is null, the space will be created in the library
            spaceId: parentSpace?.id,
            name: finalValue,
          },
        });
        setInputValue("");
        close();
        onConfirm?.(newSpace);
      } catch (error) {
        const errorMessage = error.message || "Failed to create a new space.";
        addErrorMessage(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      title={
        <div>
          New Space{" "}
          <span className={styles.titleSpan}>
            in {parentSpace?.name || "Library"}
          </span>
        </div>
      }
      onClose={close}
      PaperProps={{
        className: styles.root,
      }}
    >
      <DialogSection>
        <form onSubmit={handleSubmit}>
          <div className={styles.spaceName}>
            <label htmlFor="space-name">Space Name</label>
            <TextInput
              isSingleLine
              autoFocus
              required
              type="text"
              inputId="space-name"
              placeholder="Space Name"
              value={inputValue}
              onChange={setInputValue}
              onSubmit={handleSubmit}
              isReadOnly={isSubmitting}
            />
          </div>
          <DialogButtons className={styles.actions}>
            <Button
              type="button"
              size={BUTTON_SIZE.XSMALL}
              theme={BUTTON_THEME.SECONDARY}
              onClick={close}
              disabled={isSubmitting}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              size={BUTTON_SIZE.XSMALL}
              theme={BUTTON_THEME.CTA}
              disabled={!inputValue.trim() || isSubmitting}
            >
              Create Space
            </Button>
          </DialogButtons>
        </form>
      </DialogSection>
    </Dialog>
  );
};
