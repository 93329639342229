// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eJeRoaORl0mryrL6xE8o{display:flex;align-items:center;gap:8px}@media screen and (max-width: 768px){.eJeRoaORl0mryrL6xE8o{flex-direction:column;align-items:flex-start}}.tJd7zothXMMrOFlY3ZX1{display:flex;flex-direction:column;gap:4px}", "",{"version":3,"sources":["webpack://./src/App/components/Callouts/PinnedVersionCallout/PinnedVersionCallout.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,qCALF,sBAMI,qBAAA,CACA,sBAAA,CAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":["@import \"App/styles/_variables\";\n\n.inner {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n\n  @media screen and (max-width: $m-screen-width) {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "eJeRoaORl0mryrL6xE8o",
	"content": "tJd7zothXMMrOFlY3ZX1"
};
export default ___CSS_LOADER_EXPORT___;
