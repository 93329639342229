import React from "react";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { ReactComponent as GiftFillSVG } from "App/common/icons-v2/gift-fill.svg";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import styles from "./AudioQualityCallout.module.scss";

export const AudioQualityCallout = () => {
  const { showPlanPickerDialog } = usePlanLimitsContext();

  return (
    <aside className={styles["audio-quality-callout"]}>
      <h3 className={styles["audio-quality-callout__title"]}>
        Quality Controls
      </h3>
      <p className={styles["audio-quality-callout__description"]}>
        Share and stream full-quality, lossless audio on any device
      </p>
      <div className={styles["audio-quality-callout__ctas"]}>
        <Button
          theme={BUTTON_THEME.MONETIZATION}
          size={BUTTON_SIZE.XXSMALL}
          startIcon={<GiftFillSVG />}
          type="button"
          onClick={() => {
            showPlanPickerDialog();
          }}
          aria-label="Upgrade your plan"
        >
          Upgrade
        </Button>
        <a
          href="https://help.highnote.fm/en/articles/9844165-uploading-and-managing-tracks#h_b09f079fe6"
          target="_blank"
          rel="noreferrer"
          aria-label="Learn more about upgrading your plan"
          className={styles["audio-quality-callout__learn-more"]}
        >
          Learn More
        </a>
      </div>
    </aside>
  );
};
