import React, { useMemo, useState } from "react";
import { Space } from "@highnote/server/src/core/entities";
import { CreateSpaceWithNameDialog } from "./CreateSpaceWithNameDialog";

export type CreateSpaceProps = {
  parentSpace: null | {
    id?: string;
    name?: string;
  };
  onConfirm?: (newSpace: Space) => void;
};

/**
 * Hook to manage the create space dialog
 * @example
 * const { openCreateSpaceDialog, renderCreateSpaceDialog } = useCreateSpaceDialog();
 *
 * const handleCreateSpace = () => {
 *   openCreateSpaceDialog({
 *     parentSpace: { id: "123", name: "Parent Space" },
 *     onConfirm: (space) => {
 *       console.log("Space created:", space);
 *     },
 *   });
 * };
 *
 * return (
 *   <>
 *     <button onClick={handleCreateSpace}>Create Space</button>
 *     {renderCreateSpaceDialog}
 *   </>
 * );
 */
export const useCreateSpaceDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<CreateSpaceProps | null>(null);

  const openDialog = (props: CreateSpaceProps | null) => {
    // If props is null, the space will be created in the library
    setDialogProps(props);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDialogProps(null);
  };

  const renderDialog = useMemo(() => {
    if (!dialogProps) return null;

    return (
      <CreateSpaceWithNameDialog
        isOpen={isOpen}
        close={closeDialog}
        parentSpace={dialogProps.parentSpace}
        onConfirm={dialogProps.onConfirm}
      />
    );
  }, [dialogProps, isOpen]);

  return {
    openCreateSpaceDialog: openDialog,
    closeCreateSpaceDialog: closeDialog,
    renderCreateSpaceDialog: <>{renderDialog}</>,
  };
};
