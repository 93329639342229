import { useTrack } from "App/components/useEntities/useTrack";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { v4 as uuidv4 } from "uuid";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { Card } from "./useCommentCards";
import { useSpaceCommentsContext } from "./useSpaceComments";
import { useSystemEditing } from "./useSystemEditing";
import { ToastMessageContent, useToast } from "./useToast";

type FocusedCardOptions = {
  edit?: boolean;
  expand?: boolean;
  localInstanceId?: string;
};

type FocusedCardContextProps = {
  focusedCardId: Id;
  newCard: Card;
  focusedCardOptions: FocusedCardOptions;
};

const FocusedCardContext = createContext<FocusedCardContextProps>({
  focusedCardId: undefined,
  newCard: undefined,
  focusedCardOptions: undefined,
});

type FocusOnCardContextProps = {
  focusOnCard: (
    id?: Id,
    newCard?: Card,
    opts?: FocusedCardOptions,
  ) => Promise<void>;
};

const FocusOnCardContext = createContext<FocusOnCardContextProps>({
  focusOnCard: () => Promise.resolve(),
});

export const FocusedCardContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { versionId, commentId } = useUrlContext();
  const { addMessage } = useToast();
  const { comments, commentsLoading } = useSpaceCommentsContext();
  const history = useHistory();
  const { continueDespiteUnsavedChanges } = useSystemEditing();
  const [newCard, setNewCard] = useState<Card>();
  const [focusedCardId, setFocusedCardId] = useState<string>();
  const [focusedCardOptions, setFocusedCardOptions] =
    useState<FocusedCardOptions>();
  const sync = useCarouselSync();
  const {
    setCurrentTrackVersion,
    currentTrackVersion,
    pinnedTrackVersions,
    trackVersions,
  } = useTrack();

  useEffect(() => {
    if (commentsLoading) return;
    if (!versionId && !commentId) return;

    let verifiedVersion;
    let verifiedComment;

    if (commentId) {
      const cardComments = comments.filter((c) => !!c.trackId);
      const cardFromUrl = cardComments.find((c) => c.id === commentId);
      const rootId = cardFromUrl?.parentComment || cardFromUrl?.id;
      const rootCard = cardComments.find((c) => c.id === rootId);
      verifiedComment = rootCard;
    }

    if (verifiedComment) {
      const verifiedVersionId = (verifiedComment.trackVersionIds || [])[0];
      verifiedVersion = trackVersions.find((v) => v.id === verifiedVersionId);
    } else {
      verifiedVersion = trackVersions.find((v) => v.id === versionId);
    }

    if (commentId && !verifiedComment) {
      // Requested comment does not exist
      const toastId = uuidv4();
      addMessage({
        id: toastId,
        children: (
          <ToastMessageContent
            type="warning"
            id={toastId}
            text={`Cannot find the comment specified in this URL; it may have been deleted.`}
          />
        ),
        expireAt: Date.now() + 10000, // 10 seconds from now
      });
    } else if (currentTrackVersion?.id !== verifiedVersion?.id) {
      // Requested comment exists, but we're not on the correct version
      if (!verifiedVersion) {
        // Requested version does not exist
        const toastId = uuidv4();
        addMessage({
          id: toastId,
          children: (
            <ToastMessageContent
              type="warning"
              id={toastId}
              text={`Cannot find the version specified in this URL; it may have been deleted.`}
            />
          ),
          expireAt: Date.now() + 10000, // 10 seconds from now
        });
      } else if (!pinnedTrackVersions.includes(verifiedVersion.id)) {
        // Requested version is unpinned
        const toastId = uuidv4();
        addMessage({
          id: toastId,
          children: (
            <ToastMessageContent
              type="warning"
              id={toastId}
              text={`Cannot view the version specified in this URL because it is currently unpinned. Please pin it first and try again.`}
            />
          ),
          expireAt: Date.now() + 10000, // 10 seconds from now
        });
      } else {
        // Requested version exists, and is pinned! Switch!
        setCurrentTrackVersion(verifiedVersion);
        return;
      }
    } else if (verifiedComment) {
      // Requested comment exists, and we're already on the correct version
      sync.onCardIdClick(verifiedComment.id);
      focusOnCard(verifiedComment?.id, undefined, { expand: true });
    }

    // Remove the commentId from the URL
    const query = new URLSearchParams(location.search);
    query.delete("commentId");
    query.delete("versionId");
    history.replace({
      pathname: location.pathname,
      search: query.toString(),
    });
  }, [
    versionId,
    commentId,
    commentsLoading,
    comments,
    currentTrackVersion,
    pinnedTrackVersions,
  ]);

  const focusOnCard = useCallback(
    async (id?: string, newCard?: Card, opts?: FocusedCardOptions) => {
      const shouldContinue = continueDespiteUnsavedChanges();
      if (!shouldContinue) {
        throw new Error("Aborting! Keep my unsaved changes.");
      }

      if (!id) {
        setFocusedCardId(undefined);
        setNewCard(undefined);
        setFocusedCardOptions(undefined);
        return;
      }

      setFocusedCardId(id);
      if (newCard) setNewCard(newCard);
      else setNewCard(undefined);
      setFocusedCardOptions(opts);
      sync.onCardIdClick(id);
    },
    [],
  );

  const focusedCardContextValue = useMemo(
    () => ({
      focusedCardId,
      focusedCardOptions,
      newCard,
    }),
    [focusedCardId, focusedCardOptions, newCard],
  );

  const focusedOnCardContextValue = useMemo(
    () => ({
      focusOnCard,
    }),
    [],
  );

  return (
    <FocusedCardContext.Provider value={focusedCardContextValue}>
      <FocusOnCardContext.Provider value={focusedOnCardContextValue}>
        {children}
      </FocusOnCardContext.Provider>
    </FocusedCardContext.Provider>
  );
};

export const useFocusedCard = () => useContext(FocusedCardContext);

// Separate out this method to prevent unnecessary rerendering when the
// other focused card state properties change.
export const useFocusOnCard = () => useContext(FocusOnCardContext);
