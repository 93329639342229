import "./NewEntityMenu.scss";
import React, { useRef, useState } from "react";
import { UploadTrackButton } from "App/components/UploadTrackButton";
import { ReactComponent as FolderHollowSVG } from "App/common/icons/folder-hollow.svg";
import { ReactComponent as MusicNoteSVG } from "App/common/icons/music-note.svg";
import { ReactComponent as CaretDownSVG } from "App/common/icons/caret-down.svg";
import { ReactComponent as PlusCircleHollowSVG } from "App/common/icons/plus-circle-hollow.svg";
import { Menu, MenuItem } from "App/common/Menu";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { COLLECTION_ID } from "@highnote/server/src/core/entities";
import { useHistory } from "react-router";
import { useCreateSpaceDialog } from "App/components/Dialog/CreateSpace/useCreateSpaceDialog";

export const NewEntityMenu = ({
  disabled,
  showEntityTypes = [COLLECTION_ID.SPACE, COLLECTION_ID.TRACK],
  buttonTheme = BUTTON_THEME.SECONDARY,
  buttonText,
  hideIcon,
  onCreate,
}: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: {};
  disabled?: boolean;
  showEntityTypes?: COLLECTION_ID[];
  buttonTheme?: BUTTON_THEME;
  buttonText?: string;
  hideIcon?: boolean;
  onCreate?: () => void;
}) => {
  const history = useHistory();
  const { openCreateSpaceDialog, renderCreateSpaceDialog } =
    useCreateSpaceDialog();

  const menuRef = useRef<HTMLDivElement>();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <div className="new-entity-menu-container">
      <div ref={menuRef}>
        <Button
          size={BUTTON_SIZE.SMALL}
          theme={buttonTheme}
          disabled={!!disabled}
          onClick={() => setMenuOpen(true)}
        >
          {!hideIcon && <PlusCircleHollowSVG />}
          <span>{buttonText || "Create"}</span>
          <CaretDownSVG />
        </Button>
      </div>
      <Menu
        className="new-entity-menu"
        anchorEl={menuRef.current}
        onClose={() => setMenuOpen(false)}
        open={isMenuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {showEntityTypes.includes(COLLECTION_ID.SPACE) && (
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              openCreateSpaceDialog({
                parentSpace: null,
                onConfirm: (space) => {
                  history.push(`/space/${space.id}`);
                  onCreate && onCreate();
                },
              });
            }}
          >
            <FolderHollowSVG />
            New Space
          </MenuItem>
        )}
        {showEntityTypes.includes(COLLECTION_ID.TRACK) && (
          <UploadTrackButton>
            <MenuItem onClick={() => setMenuOpen(false)}>
              <MusicNoteSVG />
              New Track
            </MenuItem>
          </UploadTrackButton>
        )}
      </Menu>
      {renderCreateSpaceDialog}
    </div>
  );
};
