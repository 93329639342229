import React from "react";
import styles from "./BaseCallout.module.scss";
import classNames from "classnames";

type BaseCalloutTheme = "monetization";

export const BaseCalloutCard = ({
  theme = "monetization",
  className,
  children,
}: {
  theme?: BaseCalloutTheme;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <aside
      className={classNames(
        styles["card"],
        {
          [styles.monetization]: theme === "monetization",
        },
        className,
      )}
    >
      {children}
    </aside>
  );
};

export const BaseCalloutTitle = ({
  theme = "monetization",
  className,
  children,
}: {
  theme?: BaseCalloutTheme;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <h3
      className={classNames(
        styles["title"],
        {
          [styles.monetization]: theme === "monetization",
        },
        className,
      )}
    >
      {children}
    </h3>
  );
};

export const BaseCalloutDescription = ({
  theme = "monetization",
  className,
  children,
}: {
  theme?: BaseCalloutTheme;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <p
      className={classNames(
        styles["description"],
        {
          [styles.monetization]: theme === "monetization",
        },
        className,
      )}
    >
      {children}
    </p>
  );
};

export const BaseCalloutActionToolbar = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={classNames(styles["action-toolbar"], className)}>
      {children}
    </div>
  );
};
