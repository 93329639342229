import React, { useEffect, useRef, useState } from "react";

import {
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  PERMISSION,
} from "@highnote/server/src/core/shared-util";
import { highnote } from "@highnote/server/src/sdk";
import {
  ConnectedUserAvatar,
  USER_AVATAR_SIZE,
} from "App/common/UserAvatar/UserAvatar";
import { useSpaceContext } from "App/common/useSpace";
import { useToast } from "App/common/useToast";
import { useAuth } from "App/components/Auth";
import { EditableArtwork } from "App/components/EditableArtwork";
import { EditableText } from "App/components/EditableText";
import { GlobalAudioIcon } from "App/components/GlobalAudioPlayer/GlobalAudioPlayer";
import { DownloadSpaceMenu } from "../DownloadSpaceV2";
import styles from "./SpaceHero.module.scss";
import { useGlobalAudioPlayer } from "App/components/GlobalAudioPlayer";
import { Skeleton } from "App/common/Skeleton/Skeleton";

type Props = {
  spaceItemsCount?: number;
  spaceTracksCount?: number;
};

export const SpaceHero = ({ spaceItemsCount, spaceTracksCount }: Props) => {
  const unmountedRef = useRef<boolean>(false);
  const { isAllowed } = useAuth();
  const { disabled } = useGlobalAudioPlayer();
  const { space, spaceArtworkUrl, setSpaceArtworkUrl, spaceLoading } =
    useSpaceContext();
  const { toasted } = useToast();

  const canManage = isAllowed(PERMISSION.TO_MANAGE_SPACE, { space });
  const canDownload = isAllowed(PERMISSION.TO_DOWNLOAD_SPACE, {
    space,
  });

  useEffect(
    () => () => {
      unmountedRef.current = true;
    },
    [],
  );

  // This is a bit of a hack to get the artwork to display when the space does not have artwork
  // but a non-blank template is selected.
  const hasArtwork = !!spaceArtworkUrl;
  const isNonBlankTemplate = space.spaceSetup === false; // Alternative template selected if a boolean (false)
  const isTemplateUndecided = typeof space.spaceSetup !== "boolean"; // No template selected yet if not a boolean
  const shouldShowArtwork =
    hasArtwork || (isNonBlankTemplate && !isTemplateUndecided);

  return (
    <div className={styles.hero} data-cypress-id="highnote-space-home-hero">
      <div className={styles["space-preview"]}>
        {shouldShowArtwork && (
          <span className={styles["space-art-wrapper"]}>
            <EditableArtwork
              blurredBackground="small"
              disabled={!canManage}
              artworkUrl={spaceArtworkUrl}
              fallbackArtworkUrl={"/public/default-space-artwork.png"}
              onChange={async (file, tempUrl) => {
                const cachedArtworkUrl = spaceArtworkUrl;
                setSpaceArtworkUrl(tempUrl);

                try {
                  await toasted({
                    promise: highnote.updateSpace({
                      id: space?.id,
                      data: {
                        artworkFile: file?.id || null,
                      },
                    }),
                    successMessage: `Space artwork ${file ? "saved" : "removed"}.`,
                    errorMessage: `Could not ${
                      file ? "update" : "remove"
                    } Space artwork. Please try again.`,
                  });
                } catch (e) {
                  if (unmountedRef.current) return;
                  setSpaceArtworkUrl(cachedArtworkUrl);
                }
              }}
            />
          </span>
        )}

        <div className={styles["space-info"]}>
          <EditableText
            isLoading={spaceLoading}
            data-cypress-id="space-name"
            className={styles["space-name"]}
            disabled={!canManage}
            value={space?.name}
            maxLength={MAX_TITLE_LENGTH}
            required
            onChange={async (name) => {
              if (name === space?.name) return;

              if (space?.id) {
                await toasted({
                  promise: highnote.updateSpace({
                    id: space.id,
                    data: { name },
                  }),
                  successMessage: "Space name saved!",
                  errorMessage: "Could not save Space name. Please try again.",
                });
              }
            }}
          />

          {space.description && (
            <>
              <div className={styles.spacer}></div>
              <EditableText
                lineClamp={3}
                isLoading={spaceLoading}
                data-cypress-id="space-description"
                className={styles["space-description"]}
                disabled={!canManage}
                value={space?.description}
                maxLength={MAX_DESCRIPTION_LENGTH}
                placeholder={
                  canManage ? "Add a topic or description for this Space" : ""
                }
                onChange={async (description) => {
                  if (description === space?.description) return;
                  if (space?.id) {
                    await toasted({
                      promise: highnote.updateSpace({
                        id: space.id,
                        data: { description },
                      }),
                      successMessage: "Space description saved!",
                      errorMessage:
                        "Could not save Space description. Please try again.",
                    });
                  }
                }}
              />
            </>
          )}

          <SpaceHeroMeta spaceItemsCount={spaceItemsCount} />

          {(spaceTracksCount > 0 || spaceItemsCount > 0) && (
            <div className={styles["space-header-action-buttons"]}>
              {!disabled && spaceTracksCount > 0 && (
                <GlobalAudioIcon
                  canUpdateQueueOnPlay
                  type="button"
                  fullWidth={canDownload}
                />
              )}
              {canDownload && spaceItemsCount > 0 && (
                <DownloadSpaceMenu space={space} fullWidth />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SpaceHeroMeta = ({ spaceItemsCount }: { spaceItemsCount: number }) => {
  const { space } = useSpaceContext();
  const [spaceOwnerName, setSpaceOwnerName] = useState("");
  const [isNameLoading, setIsNameLoading] = useState(false);

  useEffect(() => {
    if (!space?.createdBy) return;

    setIsNameLoading(true);
    highnote
      .getUsersPublic({ ids: [space.createdBy] })
      .then((user) => {
        setSpaceOwnerName(user[space.createdBy].name);
      })
      .catch(() => {
        setSpaceOwnerName("");
      })
      .finally(() => {
        setIsNameLoading(false);
      });

    return () => {
      setIsNameLoading(false);
    };
  }, []);

  return (
    <figure className={styles["space-meta"]}>
      <ConnectedUserAvatar
        userId={space.createdBy}
        size={USER_AVATAR_SIZE.SMALL}
      />
      <figcaption>
        {isNameLoading ? (
          <Skeleton />
        ) : (
          <>
            {spaceOwnerName} • <span>{spaceItemsCount ?? 0} Items</span>
          </>
        )}
      </figcaption>
    </figure>
  );
};
