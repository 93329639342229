import React, { useEffect, useRef, useState } from "react";
import { Popover } from "@mui/material";
import classNames from "classnames";

import { daw } from "@highnote/daw/src";
import { useContainer } from "@highnote/daw/src/Container";
import {
  AUDIO_QUALITY,
  audioQualityOptions,
  SUBSCRIPTION_TIER,
} from "@highnote/server/src/core/entities";
import { ReactComponent as JumpForward10SVG } from "App/common/icons-v2/arrow-go-forward-10.svg";
import { ReactComponent as JumpForward5SVG } from "App/common/icons-v2/arrow-go-forward-5.svg";
import { ReactComponent as CaratDownSVG } from "App/common/icons-v2/carat-down.svg";
import { ReactComponent as EqualizerSVG } from "App/common/icons-v2/equalizer.svg";
import { ReactComponent as GiftFillSVG } from "App/common/icons-v2/gift-fill.svg";
import { ReactComponent as LockSVG } from "App/common/icons-v2/lock.svg";
import { ReactComponent as SkipForwardSVG } from "App/common/icons-v2/skip-forward.svg";
import { ReactComponent as QualityHighSVG } from "App/common/icons/quality-high.svg";
import { usePlanLimitsContext } from "App/common/PlanLimits/usePlanLimits";
import { useViewport } from "App/common/useViewport";
import { Button, BUTTON_SIZE, BUTTON_THEME } from "App/core/Button";
import { SKIP_AUDIO_MODE, useGlobalAudioPlayer } from ".";
import { useAuth } from "../Auth";
import { AudioQualityCallout } from "../Callouts/AudioQualityCallout/AudioQualityCallout";
import "./ToggleAudioQuality.scss";

type Stats = {
  description?: string;
  bitRate?: number;
  sampleRate?: number;
  format?: string;
};

export const useAudioQualityStats = () => {
  // The existing useContainer function is used to get the daw state
  // but it subscribes to state/event changes, unlike the daw.state object.
  // We use this instead, to force a re-render, as the dawTrack.sourceId was always delayed
  // due to not subscribing to the state changes. We subscribe directly to the daw "tracks" property only.
  const dawState = useContainer(daw, ["tracks"]);
  const { quality, nowPlaying } = useGlobalAudioPlayer();
  const [stats, setStats] = useState<Stats>({});

  useEffect(() => {
    const dawTrack = daw.getTrack(nowPlaying?.id);
    if (!dawTrack) return;

    const newStats: Stats = {};
    const option = audioQualityOptions.find((o) =>
      dawTrack.source?.includes(o.filename),
    );

    if (
      !option ||
      option.quality === AUDIO_QUALITY.ORIGINAL ||
      quality === AUDIO_QUALITY.ORIGINAL
    ) {
      const { bit_rate, sample_rate } = nowPlaying?.file?.metadata || {};
      newStats.bitRate = bit_rate;
      newStats.sampleRate = sample_rate;
      newStats.format = option?.format;
      newStats.description = "Lossless Quality";
    } else {
      newStats.bitRate = option.bitRate;
      newStats.sampleRate = option.samplingRate;
      newStats.format = option.format;
      newStats.description = `${
        quality === AUDIO_QUALITY.LOW ? "Low" : "Standard"
      } Quality`;
      if (quality === AUDIO_QUALITY.LOW) {
        newStats.description = "Low Quality";
      } else if (quality === AUDIO_QUALITY.HIGH) {
        newStats.description = "Standard Quality";
      }
    }

    if (option?.quality !== quality) {
      if (quality === AUDIO_QUALITY.HIGH) {
        newStats.description = "Highest Available";
      }
    }

    setStats(newStats);
  }, [nowPlaying, quality, dawState?.tracks]);

  return {
    description: stats.description,
    detail: (
      <>
        {stats.bitRate ? `${Math.floor(stats.bitRate / 1000)}kbps` : "--"}
        {" / "}
        {stats.sampleRate ? `${(stats.sampleRate / 1000).toFixed(1)}kHz` : "--"}
        {stats.format ? ` ${stats.format.toUpperCase()}` : ""}
      </>
    ),
  };
};

const NowPlayingStats = ({ qualityLocked }: { qualityLocked: boolean }) => {
  const audioQualityStats = useAudioQualityStats();

  return (
    <div className="NowPlayingStats" data-cypress-id="now-playing-stats">
      <span>
        {qualityLocked ? "Audio quality locked by a space manager. " : ""}
      </span>
      <span className="quality">{audioQualityStats.description}</span>
      <br />
      {audioQualityStats.detail}
    </div>
  );
};

export const ToggleAudioQuality = React.memo(
  ({ isAudioQualityLocked }: { isAudioQualityLocked: boolean }) => {
    const { isMinLargeScreenSize } = useViewport();
    const { user } = useAuth();
    const { quality, setAudioQuality, skipAudioMode, setSkipAudioMode } =
      useGlobalAudioPlayer();
    const { showPlanPickerDialog } = usePlanLimitsContext();
    const [isOpen, setIsOpen] = useState(false);
    const triggerButtonRef = useRef<HTMLButtonElement>(null);

    const isAnonymousUser = !user;
    const showMonetizationCallout =
      user?.subscriptionTier === SUBSCRIPTION_TIER.FREE;

    return (
      <div
        className={classNames("ToggleAudioQuality", {
          monetization: showMonetizationCallout,
        })}
        data-cypress-id="toggle-audio-quality"
      >
        <Button
          onClick={() => setIsOpen((prev) => !prev)}
          endIcon={isMinLargeScreenSize && <CaratDownSVG />}
          ref={triggerButtonRef}
          aria-label={`Click to change audio quality. Currently ${quality} quality`}
          theme={
            !isMinLargeScreenSize
              ? BUTTON_THEME.ICON
              : showMonetizationCallout && BUTTON_THEME.MONETIZATION_INVERSE
          }
          size={BUTTON_SIZE.XSMALL}
        >
          <span
            className={classNames("display", {
              "display-mobile": !isMinLargeScreenSize,
            })}
            data-quality={quality}
          >
            {isMinLargeScreenSize ? (
              <>
                <EqualizerSVG />
                <span className="quality">
                  {quality === AUDIO_QUALITY.ORIGINAL && <>Lossless</>}
                  {quality === AUDIO_QUALITY.HIGH && <>Standard</>}
                </span>
              </>
            ) : (
              <>
                <QualityHighSVG />
                <span className="quality">
                  {quality === AUDIO_QUALITY.ORIGINAL && <>LOSS</>}
                  {quality === AUDIO_QUALITY.HIGH && <>STND</>}
                </span>
              </>
            )}
          </span>
        </Button>
        {showMonetizationCallout && <GiftFillSVG />}
        <Popover
          open={isOpen}
          onClose={() => setIsOpen(false)}
          anchorEl={triggerButtonRef.current}
          className="ToggleAudioQuality-menu"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ul
            className="ToggleAudioQuality-menu-list"
            role="group"
            aria-labelledby="group-title-audio-quality"
          >
            <li
              id="group-title-audio-quality"
              className="ToggleAudioQuality-menu-group-title"
            >
              Audio Quality
            </li>
            {isAudioQualityLocked ? (
              <li>
                <Button
                  className={classNames("ToggleAudioQuality-menu-item", {
                    "ToggleAudioQuality-menu-item-active":
                      quality === AUDIO_QUALITY.ORIGINAL,
                  })}
                  endIcon={<LockSVG />}
                  onClick={() => setAudioQuality(AUDIO_QUALITY.ORIGINAL)}
                  theme={BUTTON_THEME.TEXT}
                  disabled
                >
                  Lossless
                </Button>
              </li>
            ) : (
              <>
                {showMonetizationCallout ? (
                  <>
                    <li>
                      <AudioQualityCallout />
                    </li>
                    <li>
                      <Button
                        className={classNames(
                          "ToggleAudioQuality-menu-item",
                          "ToggleAudioQuality-menu-item__monetization-lock",
                        )}
                        endIcon={<GiftFillSVG />}
                        onClick={showPlanPickerDialog}
                        theme={BUTTON_THEME.TEXT}
                        autoFocus
                        aria-label="Click here to upgrade your Highnote account to unlock Lossless audio quality"
                      >
                        Lossless
                      </Button>
                    </li>
                  </>
                ) : (
                  <li>
                    <Button
                      className={classNames("ToggleAudioQuality-menu-item", {
                        "ToggleAudioQuality-menu-item-active":
                          quality === AUDIO_QUALITY.ORIGINAL,
                      })}
                      onClick={() => {
                        setAudioQuality(AUDIO_QUALITY.ORIGINAL);
                      }}
                      theme={BUTTON_THEME.TEXT}
                      disabled={isAnonymousUser}
                      autoFocus={!isAnonymousUser}
                      endIcon={isAnonymousUser && <LockSVG />}
                    >
                      Lossless
                    </Button>
                  </li>
                )}
                <li>
                  <Button
                    className={classNames("ToggleAudioQuality-menu-item", {
                      "ToggleAudioQuality-menu-item-active":
                        quality === AUDIO_QUALITY.HIGH,
                    })}
                    onClick={() => setAudioQuality(AUDIO_QUALITY.HIGH)}
                    theme={BUTTON_THEME.TEXT}
                  >
                    Standard
                  </Button>
                </li>
              </>
            )}
            <li>
              <NowPlayingStats qualityLocked={isAudioQualityLocked} />
            </li>
          </ul>

          <div className="ToggleAudioQuality-divider" role="separator" />

          <ul
            className="ToggleAudioQuality-menu-list"
            role="group"
            aria-labelledby="group-title-audio-controls"
          >
            <li
              id="group-title-audio-controls"
              className="ToggleAudioQuality-menu-group-title"
            >
              Playback Controls
            </li>
            <li>
              <Button
                className={classNames("ToggleAudioQuality-menu-item", {
                  "ToggleAudioQuality-menu-item-active":
                    skipAudioMode === SKIP_AUDIO_MODE.FULL_SKIP,
                })}
                endIcon={<SkipForwardSVG />}
                onClick={() => setSkipAudioMode(SKIP_AUDIO_MODE.FULL_SKIP)}
                theme={BUTTON_THEME.TEXT}
                autoFocus={isAudioQualityLocked}
              >
                Full Skip
              </Button>
            </li>
            <li>
              <Button
                className={classNames("ToggleAudioQuality-menu-item", {
                  "ToggleAudioQuality-menu-item-active":
                    skipAudioMode === SKIP_AUDIO_MODE.NUDGE_10S,
                })}
                endIcon={<JumpForward10SVG />}
                onClick={() => setSkipAudioMode(SKIP_AUDIO_MODE.NUDGE_10S)}
                theme={BUTTON_THEME.TEXT}
              >
                10s Nudge
              </Button>
            </li>
            <li>
              <Button
                className={classNames("ToggleAudioQuality-menu-item", {
                  "ToggleAudioQuality-menu-item-active":
                    skipAudioMode === SKIP_AUDIO_MODE.NUDGE_5S,
                })}
                endIcon={<JumpForward5SVG />}
                onClick={() => setSkipAudioMode(SKIP_AUDIO_MODE.NUDGE_5S)}
                theme={BUTTON_THEME.TEXT}
              >
                5s Nudge
              </Button>
            </li>
          </ul>
        </Popover>
      </div>
    );
  },
);

ToggleAudioQuality.displayName = "ToggleAudioQuality (Memoized)";
