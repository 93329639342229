import "./CommentCard.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import animateScrollTo from "animated-scroll-to";
import classNames from "classnames";
import { useCarouselSync } from "App/routes/Main/Space/Carousel/useCarouselSync";
import { useSpaceContext } from "../useSpace";
import { CommentContent } from "./CommentContent";
import { CommentContextProvider, useCommentContext } from "../useComment";
import { ReplyStats } from "./ReplyStats";
import { CommentMeta } from "./CommentMeta";
import { NewCommentForm } from "./NewCommentForm";
import { Button, BUTTON_THEME, BUTTON_SIZE } from "../../core/Button";
import { useAuth } from "App/components/Auth";
import { PERMISSION } from "@highnote/server/src/core/shared-util";
import { useUrlContext } from "App/routes/Main/useUrlContext";
import { ReactComponent as CaretLeftSVG } from "App/common/icons/caret-left.svg";
import { useFocusedCard, useFocusOnCard } from "../useFocusedCard";
import { useNotificationsContext } from "../useNotifications";
import { KNOCK_WORKFLOW } from "@highnote/server/src/core/entities";

export const FOCUSED_COMMENT_CARD_ID = "focused-comment-card";

const _CommentCard = ({
  containerClassName,
  commentWrapperClassName,
  localInstanceId,
}: {
  containerClassName?: string;
  commentWrapperClassName?: string;
  localInstanceId?: string;
}) => {
  const sync = useCarouselSync();
  const { notifications, markAsRead } = useNotificationsContext();
  const { focusOnCard } = useFocusOnCard();
  const { focusedCardOptions, focusedCardId } = useFocusedCard();
  const { comment, replies, isPublished, isEditing, setEditing } =
    useCommentContext();
  const [isExpanded, setExpanded] = useState(false);
  const { isAllowed } = useAuth();
  const { trackId } = useUrlContext();
  const { space, spaceId } = useSpaceContext();
  const scrollableRef = useRef<HTMLDivElement>();
  const [isScrolled, setScrolled] = useState<boolean>(false);

  const canReplyToCommentInSpace = isAllowed(
    PERMISSION.TO_REPLY_TO_COMMENT_IN_SPACE,
    { space, comment },
  );

  useEffect(() => {
    if (isEditing) return;
    setExpanded(false);
  }, [isEditing]);

  useEffect(() => {
    if (!scrollableRef.current) return;

    const handleScroll = () => {
      setScrolled(scrollableRef.current.scrollTop > 0);
    };

    scrollableRef.current.addEventListener("scroll", handleScroll);
    return () => {
      if (!scrollableRef.current) return;
      scrollableRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [scrollableRef]);

  const animatedToLatestReply = () => {
    if (!scrollableRef.current) return;

    // Wait until the reply has rendered in the dom to measure!
    setTimeout(() => {
      animateScrollTo([null, scrollableRef.current.scrollHeight], {
        cancelOnUserAction: true,
        elementToScroll: scrollableRef.current,
        maxDuration: 300,
      });
    }, 100);
  };

  const animatedExpandRoot = () => {
    // If the card is already centered, you can just animate it into place.
    // Use a timeout to avoid getting overwritten by any other calls to
    // `setExpanded` (like in `useCommentsCarousel`).
    setTimeout(() => {
      setExpanded(true);
      animatedToLatestReply();
    });
  };

  useEffect(() => {
    // No set localInstanceId means it's a global action and should apply to all instances
    const isGlobalAction = !focusedCardOptions?.localInstanceId;
    // If the localInstanceId matches, it's a local action and should only apply to this instance
    const isLocalAction =
      focusedCardOptions?.localInstanceId === localInstanceId;

    // Handle Expansion (global or local instance)
    if (focusedCardOptions?.expand && (isGlobalAction || isLocalAction)) {
      animatedExpandRoot();
    } else {
      setExpanded(false);
    }

    // Handle editing (global or local instance)
    if (focusedCardOptions?.edit && (isGlobalAction || isLocalAction)) {
      setEditing(true);
    } else {
      setEditing(false);
    }
  }, [focusedCardOptions]);

  // Anytime the card is newly centered & expanded
  // (OR already in that state and the # of replies change)
  // Scroll the card to the latest reply.
  useEffect(() => {
    if (!isExpanded) return;
    animatedToLatestReply();
  }, [isExpanded, replies.length]);

  useEffect(() => {
    if (!isExpanded) return;

    const unreads = notifications.filter((n) => !n.read_at);
    const unreadReplies = unreads.filter(
      (n) =>
        n.source.key === KNOCK_WORKFLOW.CARD_REPLY_ADDED &&
        n.data.rootCommentId === focusedCardId,
    );

    markAsRead(unreadReplies);
  }, [isExpanded, notifications, focusedCardId]);

  const onCancel = useCallback(() => {
    focusOnCard().then(() => {
      setExpanded(false);
    });
  }, []);

  return (
    <div
      className={classNames("highnote-comment-card", containerClassName)}
      data-cypress-id="comment-card"
      data-comment-id={comment?.id}
      data-is-hidden={!focusedCardId}
      data-is-resolved={!!comment?.isResolved}
      data-is-deleted={comment?.isDeleted}
      data-is-expanded={isExpanded}
      data-is-scrolled={isScrolled}
      style={{ width: `${sync.cardWidth}px` }}
    >
      {comment && (
        <div
          className={classNames("comment-wrapper", commentWrapperClassName)}
          id={FOCUSED_COMMENT_CARD_ID}
        >
          <div className="mobile-expanded-only">
            <Button theme={BUTTON_THEME.ICON} onClick={onCancel}>
              <CaretLeftSVG />
            </Button>
            <h2>Replies</h2>
          </div>

          <div className="meta-wrapper">
            <CommentMeta showActions localInstanceId={localInstanceId} />
          </div>

          <div className="scrollable" ref={scrollableRef}>
            <div className="root-comment">
              <CommentMeta localInstanceId={localInstanceId} />
              <CommentContent onCancel={onCancel} />
            </div>

            <div className="replies">
              {replies.length ? (
                replies.map((reply) => (
                  <CommentContextProvider key={reply.id} id={reply.id}>
                    <div className="reply" data-is-deleted={reply.isDeleted}>
                      <CommentMeta showActions />
                      <CommentContent />
                    </div>
                  </CommentContextProvider>
                ))
              ) : (
                <div className="no-replies">
                  No replies yet. Start a conversation.
                </div>
              )}
            </div>
          </div>
          {isPublished && !isEditing && !isExpanded && (
            <div className="reply-buttons">
              {!!replies.length && (
                <button
                  onClick={animatedExpandRoot}
                  className="reply-stats-button"
                >
                  <ReplyStats replies={replies} />
                </button>
              )}
              {canReplyToCommentInSpace && (
                <button
                  className="reply-button"
                  onClick={() => {
                    animatedExpandRoot();
                  }}
                >
                  Reply
                </button>
              )}
            </div>
          )}

          {isExpanded && (
            <div className="new-reply">
              {canReplyToCommentInSpace ? (
                <NewCommentForm
                  onClose={onCancel}
                  spaceId={spaceId}
                  trackId={trackId}
                />
              ) : (
                <Button
                  size={BUTTON_SIZE.XSMALL}
                  theme={BUTTON_THEME.TEXT}
                  className="reply-button"
                  onClick={onCancel}
                >
                  Close
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const CommentCard = ({
  containerClassName,
  commentWrapperClassName,
  localInstanceId,
}: {
  containerClassName?: string;
  commentWrapperClassName?: string;
  localInstanceId?: string;
}) => {
  const { focusedCardId, newCard } = useFocusedCard();

  return (
    <CommentContextProvider id={focusedCardId} initialState={newCard?.comment}>
      <_CommentCard
        containerClassName={containerClassName}
        commentWrapperClassName={commentWrapperClassName}
        localInstanceId={localInstanceId}
      />
    </CommentContextProvider>
  );
};
