import React, { PropsWithChildren } from "react";
import { ReactComponent as SkipBackwardSVG } from "App/common/icons-v2/skip-back.svg";
import { ReactComponent as SkipForwardSVG } from "App/common/icons-v2/skip-forward.svg";
import { ReactComponent as JumpBack5SVG } from "App/common/icons-v2/arrow-go-back-5.svg";
import { ReactComponent as JumpBack10SVG } from "App/common/icons-v2/arrow-go-back-10.svg";
import { ReactComponent as JumpForward5SVG } from "App/common/icons-v2/arrow-go-forward-5.svg";
import { ReactComponent as JumpForward10SVG } from "App/common/icons-v2/arrow-go-forward-10.svg";
import { BUTTON_SIZE, BUTTON_THEME, Button } from "App/core/Button";
import { SKIP_AUDIO_MODE, useGlobalAudioPlayer } from ".";
import { useViewport } from "App/common/useViewport";

interface BaseMediaControlsProps {
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
}

export const BaseMediaControls = ({
  children,
  isPrevDisabled,
  isNextDisabled,
  onPrevClick,
  onNextClick,
}: PropsWithChildren<BaseMediaControlsProps>) => {
  const { skipAudioMode } = useGlobalAudioPlayer();
  const { vw } = useViewport();
  const isMobile = vw <= 768;

  return (
    <>
      {skipAudioMode !== SKIP_AUDIO_MODE.FULL_SKIP ? (
        <>
          <Button
            className="prev"
            size={isMobile ? BUTTON_SIZE.LARGE : BUTTON_SIZE.AUTO}
            theme={BUTTON_THEME.ICON}
            onClick={onPrevClick}
            aria-label={
              skipAudioMode === SKIP_AUDIO_MODE.NUDGE_5S
                ? "Skip track back 5 seconds"
                : "Skip track back 10 seconds"
            }
          >
            {skipAudioMode === SKIP_AUDIO_MODE.NUDGE_5S ? (
              <JumpBack5SVG />
            ) : (
              <JumpBack10SVG />
            )}
          </Button>
          {children}
          <Button
            className="next"
            size={isMobile ? BUTTON_SIZE.LARGE : BUTTON_SIZE.AUTO}
            theme={BUTTON_THEME.ICON}
            onClick={onNextClick}
            aria-label={
              skipAudioMode === SKIP_AUDIO_MODE.NUDGE_5S
                ? "Skip track forward 5 seconds"
                : "Skip track forward 10 seconds"
            }
          >
            {skipAudioMode === SKIP_AUDIO_MODE.NUDGE_5S ? (
              <JumpForward5SVG />
            ) : (
              <JumpForward10SVG />
            )}
          </Button>
        </>
      ) : (
        <>
          <Button
            className="prev"
            disabled={isPrevDisabled}
            size={BUTTON_SIZE.XSMALL}
            theme={BUTTON_THEME.ICON}
            onClick={onPrevClick}
          >
            <SkipBackwardSVG />
          </Button>
          {children}
          <Button
            className="next"
            disabled={isNextDisabled}
            size={BUTTON_SIZE.XSMALL}
            theme={BUTTON_THEME.ICON}
            onClick={onNextClick}
          >
            <SkipForwardSVG />
          </Button>
        </>
      )}
    </>
  );
};
